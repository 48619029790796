<template>
  <div class="container">
    <Carousel :args="args.slide" />
    <School :args="args.school" />
  </div>
</template>

<script>
import Carousel from "@/components/common/carousel.vue";
// import School from "@/components/school/school.vue";
import School from "@/components/new_school/school.vue";
export default {
  components: {
    Carousel,
    School,
  },
  data() {
    return {
      args: {},
    };
  },
  created() {
    this.$api("/official/page/school").then(({ data }) => {
      this.args = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1440px;
}
</style>

