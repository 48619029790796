<template>
  <div class="select-container" @mouseleave="hideOptionHandler">
    <div @click="showOptionHandler" :class="{text:true, actived:showOptions}">{{text}}</div>
    <ul class="options" v-show="showOptions">
      <li @click="selectOption({id:0,name:'全部'})">全部</li>
      <li v-for="(option, idx) in options" @click="selectOption(option)" :key="idx">{{option.name}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showOptions: false,
      currOptionText: null,
    };
  },
  methods: {
    selectOption(item) {
      this.currOptionText = item.name;
      this.hideOptionHandler();
      this.$emit("change", item);
    },
    hideOptionHandler() {
      this.showOptions = false;
    },
    showOptionHandler() {
      console.log(123);
      this.showOptions = true;
    },
  },
  computed: {
    text() {
      return this.currOptionText || this.placeholder;
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.selectOption({ id: 0, name: "全部" });
      },
    },
  },
  props: {
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;
  min-width: 160px;

  .text {
    padding: 15px 44px 15px 20px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    position: relative;
    border-radius: 25px;
    border: 1px solid #999999;
    cursor: pointer;
    text-align: center;
    width: 96px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::after {
      content: " ";
      position: absolute;
      top: 16px;
      right: 20px;

      border-top: 9px solid transparent;
      border-right: 10px solid #ff6f1b;
      border-bottom: 9px solid transparent;
      border-radius: 10px;

      transition: 0.5s;
    }

    &.actived {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }

  .options {
    background-color: #fff;
    position: absolute;
    overflow: scroll;
    height: 300px;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    // background-color: #fafafa;
    // margin-top: 5px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);

    z-index: 99999;

    li {
      height: 25px;
      font-size: 16px;
      text-align: center;
      padding: 10px 0;
      color: #666;
      cursor: pointer;

      &:hover {
        color: #ff6f1b;
        background-color: #f9f9f9;
      }
    }
  }
}
</style>