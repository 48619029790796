<template>
  <div class="container">
    <div class="container-content">
      <div class="top">
        <div class="main-title">校区<font> / CAMPUS</font></div>
      </div>
      <div class="body">
        <div class="cover">
          <img :src="args.school_common_pic" />
        </div>
        <div class="content">
          <div class="filter">
            <FilterSelect
              @change="filterSelectProvinceChangeHandler"
              :options="provinces"
              placeholder="请选择所在省"
            />
            <FilterSelect
              @change="filterSelectCityChangeHandler"
              :options="cities"
              placeholder="请选择所在市"
            />
          </div>
          <div class="schools" ref="schools">
            <Item class="school"
              v-for="(item, key) in items"
              :item="item"
              :key="key"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterSelect from "@/components/common/select.vue";
import Item from "@/components/new_school/item.vue";
// import Pager from "@/components/common/pager.vue";
export default {
  components: {
    FilterSelect,
    Item,
    // Pager,
  },
  data() {
    return {
      province_id: 0,
      city_id: 0,
    };
  },
  computed: {
    cities() {
      try {
        let cities = [];
        this.args.region.cities.forEach((city) => {
          if (city.province_id == this.param.province_id) {
            cities.push(city);
          }
        });
        return cities;
      } catch (e) {
        return [];
      }
    },
    provinces() {
      try {
        return this.args.region.provinces;
      } catch (e) {
        return [];
      }
    },
    items() {
      try {
        return this.args.list.filter((school) => {
          if (this.province_id != 0 && school.province_id != this.province_id) {
            return false;
          }
          if (this.city_id != 0 && school.city_id != this.city_id) {
            return false;
          }

          return true;
        });
      } catch (e) {
        return [];
      }
    },
  },
  watch: {
    items() {
      this.$refs.schools.scrollTop = 0;
    },
  },
  props: {
    args: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    filterSelectProvinceChangeHandler(data) {
      this.province_id = data.id;
    },
    filterSelectCityChangeHandler(data) {
      this.city_id = data.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 838px;
  width: 100%;

  .container-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;

      .main-title {
        padding-top: 100px;
        font-size: $font-size-36;
        color: $font-color-black;

        font {
          color: $font-color-orange;
        }
      }
    }

    .body {
      width: 100%;
      margin-top: 65px;
      width: 1208px;

      display: flex;

      .cover {
        width: 780px;
        height: 502px;
        border-radius: 90px;
        overflow: hidden;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .content {
        margin-left: 50px;
        height: 500px;

        display: flex;
        flex-direction: column;

        .filter {
          display: flex;

          > *:last-child {
            margin-left: 20px;
          }
        }

        .schools {
          margin-top: 70px;
          flex: 1;

          overflow-y: auto;

          /* 去除滚动条- 兼容火狐和IE10+ */
          scrollbar-width: 4px;
          -ms-overflow-style: rpx;

          &::-webkit-scrollbar {
            /* Chrome去除滚动条 */
            display: inline;
            border-radius: 4px;
            background: #f9f9f9;
            width: 8px;
          }

          /*定义滑块 内阴影+圆角*/
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            width: 8px;
            height: 20px;
            background: linear-gradient(90deg, #ffa83a 0%, #ff6f1b 100%);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.09);
            border-radius: 4px;
          }

          .school {
            padding: 30px 0;
            margin-right: 60px;
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }
}
</style>