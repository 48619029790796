<template>
  <div class="school-item">
    <div class="title">
      <img class="icon" src="/static/img/school/icon_title.png" />
      <div class="name">{{ item.name }}</div>
    </div>
    <div class="content">
      <div class="text">校长：{{ item.master }}</div>
      <div class="text">地址：{{ item.address }}</div>
      <div class="text">
        期限：{{ dateFormat(item.limit_time, "y / m / d") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.school-item {
  .title {
    display: flex;

    .icon {
      width: 26px;
      height: 24px;
    }
    .name {
      margin-left: 11px;

      font-size: 24px;
      font-weight: 500;
      color: #ff6f1b;
      line-height: 33px;

      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }

  .content {
      margin-top: 15px;
    .text {
      //   width: 260px;
      //   height: 99px;
      font-size: 20px;
      color: #999999;
      line-height: 33px;

      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
}
</style>